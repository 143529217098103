/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { get_category_list } from "../store/actions/productCategoryAction";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { keys } from "../config/keys";

const AppHeaderMenu = () => {
  const dispatch = useDispatch<any>();
  const category_list = useSelector((obj: any) => obj.category_list);

  const _get_category_list = () => dispatch(get_category_list());

  const [count, setcount] = useState(0);
  useEffect(() => {
    _get_category_list();
    const minimalMenu = document.querySelector(".minimal-menu");
    console.log("minimalMenu", minimalMenu);

    const addedMenu = document.querySelectorAll(".minimal-menu-button");
    console.log("Added Menu", addedMenu.length);

    if (addedMenu.length > 1) return;
    if (!minimalMenu) return;

    // // Add minimal-menu-button before .minimal-menu
    // minimalMenu.insertAdjacentHTML(
    //   "beforebegin",
    //   `<label class="minimal-menu-button" for="mobile-nav">
    //     <span class="icon-bar"></span>
    //     <span class="icon-bar"></span>
    //     <span class="icon-bar"></span>
    //     </label><input class="minimal-menu-button" type="checkbox" id="mobile-nav" name="mobile-nav" />`
    // );

    // // // Add classes to menu elements
    // minimalMenu
    //   .querySelectorAll("ul.sub-menu")
    //   .forEach((ul: any) => ul.parentElement.classList.add("submenu"));
    // minimalMenu
    //   .querySelectorAll("div.menu-wrapper")
    //   .forEach((div: any) =>
    //     div.parentElement.classList.add("megamenu", "submenu")
    //   );

    // // Add show-submenu input before ul.sub-menu and div.menu-wrapper
    // minimalMenu
    //   .querySelectorAll("ul.sub-menu")
    //   .forEach((ul) =>
    //     ul.insertAdjacentHTML(
    //       "beforebegin",
    //       '<input class="show-submenu" type="checkbox" />'
    //     )
    //   );
    // minimalMenu
    //   .querySelectorAll("div.menu-wrapper")
    //   .forEach((div) =>
    //     div.insertAdjacentHTML(
    //       "beforebegin",
    //       '<input class="show-submenu" type="checkbox" />'
    //     )
    //   );
    // console.log("minimalMenu", minimalMenu);
  }, []);

  return (
    <nav className="main-nav">
      <div className="minimal-menu">
        <ul className="menu">
          <li className="current-menu-item">
            <Link to="/">HOME</Link>
            {/* <a href="/">Home</a> */}
          </li>
          <li className="">
            <Link to="/about-us">ABOUT US</Link>
            {/* <a href="/about-us">About us</a> */}
          </li>
          {/* <li>
            <a href="shop-fullwidth.html">SHOP</a>
            <div className="menu-wrapper">
              {category_list.length > 0 &&
                category_list
                  .filter((item: any, index: number) => index > 1 && index < 6)
                  .map((obj: any, key: number) => {
                    if (
                      obj.isactive === 1 &&
                      obj.isdelete === 0 &&
                      obj.displayonweb === 1
                    )
                      return (
                        // <div className="col-md-3" style={{ padding: "10px" }}>
                        //   <div
                        //     className="fashion-store"
                        //     style={{
                        //       backgroundImage: `url('${keys.ServicePath}/${obj.categoryimage}')`
                        //     }}
                        //   >
                        //     <a href="/productlisting">
                        //       <h3>{obj.category}</h3>
                        //       <div className="overlay"></div>
                        //     </a>
                        //   </div>
                        // </div>
                        <div
                          className="col-lg-3 col-md-4 col-sm-6"
                          key={Math.random()}
                        >
                          <h3>{obj.category}</h3>
                          <hr />
                          <p>
                            {`${obj?.description}
                        ${obj?.tagline}`}
                          </p>
                          <div className="media">
                            <div className="media-left">
                              <a href="shop-with-sidebar.html">
                                <img
                                  className="img-responsive"
                                  src={`${keys.ServicePath}/${obj.categoryimage}`}
                                  alt="img"
                                />
                              </a>
                            </div>
                            <div className="media-body">
                              <ul>
                                <li>
                                  <a href="shop-with-sidebar.html">
                                    {obj?.parentcategory}
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      );
                  })}
            </div>
          </li> */}
          <li>
            {/* <a href="#">SAREES</a> */}
            <Link to={{ pathname: `/productlisting` }}>{`SAREES`}</Link>
            <ul className="sub-menu">
              {category_list.length > 0 &&
                category_list.map((obj: any) => {
                  if (obj.isactive === 1 && obj.isdelete === 0)
                    return (
                      <li style={{ display: "flex" }} key={Math.random()}>
                        <Link
                          to={{ pathname: `/productlisting/${obj.id}` }}
                          state={{ categoryId: obj?.id }}
                        >
                          {obj.category.toUpperCase()}
                        </Link>
                        {/* <a href="/productlisting">{obj.category}</a> */}
                      </li>
                    );
                })}
            </ul>
          </li>
          <li className="">
            <Link to="#">ACCESSIORIES</Link>
          </li>
          <li className="">
            <Link to="#">BLOG</Link>
          </li>
          <li>
            <Link to={{ pathname: `/page/contact-us` }}>{`CONTACT`}</Link>
          </li>
          <li className="hidden-xs">
            <div className="wrap-search">
              <form action="#" className="search-form">
                <input type="text" placeholder="Search.." />
                <button type="submit">
                  <i className="pe-7s-search"></i>
                </button>
              </form>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default AppHeaderMenu;
