import dayjs from "dayjs";

const currencyFormat = (value) =>
  new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR"
  }).format(value);

const formatterWithoutCurrency = new Intl.NumberFormat("en-IN");
const formatterwithoutDecimal = new Intl.NumberFormat("en-IN", {
  style: "currency",
  currency: "INR",
  maximumFractionDigits: 0
});

const decimalNumber = (value) =>
  new Intl.NumberFormat("en-IN", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(value);

const formatterWithoutCurrencyWithoutDecimal = new Intl.NumberFormat("en-IN", {
  maximumFractionDigits: 0
});

export const keys = {
  //QA
  // ServicePath: "http://115.124.117.226:5056",
  // ServiceIP: "http://115.124.117.226:5056",
  // authorizedURL: "http://app.thewovenwonders.com",

  // //Live
  ServicePath: "https://api.thewovenwonders.com",
  ServiceIP: "https://api.thewovenwonders.com",
  authorizedURL: "https://thewovenwonders.com",

  //LOCAL
  // ServicePath: "http://localhost:5056",
  // ServiceIP: "http://localhost:5056",
  // authorizedURL: "http://localhost:3000",

  shortDateFormat: "DD-MM-YYYY",
  longDateFormat: "DD-MMM-YYYY",
  dbDateFormat: "YYYY-MM-DD",
  dbTimeFormat: "YYYY-MM-DD HH:mm",
  timeFormat: "HH:mm",
  currencyFormat,
  decimalNumber,
  shortDate: (value) => dayjs(value).format("DD-MM-YYYY"),
  displayTime: (value) => dayjs(value).format("hh:mm A")
};
