const Contact = () => {
  return (
    <div className="our-team-content">
      <div className="header-our-team">
        <div className="container">
          <h1>GET IN TOUCH</h1>
          <p>
            If you have any queries, concerns or suggestions, please contact us.
          </p>
        </div>
      </div>

      <hr className="gray-line" />
      <div className="the-team">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-md-12">
              <div className="team-item">
                <div className="row">
                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <h4>
                      A 19, New Mount Villa, After Nilamber Circle, opp. Akshar
                      pavilion, Vasna Bhayli Road -390012
                    </h4>
                  </div>
                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <h4>Tel. +91 6355373488</h4>
                  </div>

                  <div className="col-md-12 col-lg-12 col-sm-12">
                    <h4>Email. support@thewovenwonders.com</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
