/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
// import Select from "react-select";
// import { SelectedOption } from "../components/common/commonTypes";
// import { useDispatch } from "react-redux";
// import { useAppSelector } from "../redux/store/hooks";
// import { FINANCIAL_YEAR } from "../redux/constTypes/common";
// import { useAuth } from "../App";
// import { useNavigate } from "react-router-dom";
// import { getFinancialYear } from "../redux/reducers/common";

// type AppHeaderProps = {
//   toggleClick?: () => void;
//   isToggled?: boolean;
// };
const AppFooterTop = () => {
  // const navigate = useNavigate();
  // const { user, signout } = useAuth();
  // const { toggleClick = () => {}, isToggled = false } = props;
  // const [dropdownOpen, setDropdownOpen] = useState(false);
  // const dispatch = useDispatch<any>();
  // const [selectedFinancialYearId, setSelectedFinancialYearId] =
  //   useState<SelectedOption | null>(null);
  // const { financialYear, getFinancialYear } = useAppSelector((obj) => obj);

  // const onClickDropdownOpen = () => {
  //   setDropdownOpen(true);
  // };

  // const logout = () => {
  //   localStorage.clear();
  //   sessionStorage.clear();
  //   signout(() => {
  //     navigate("/");
  //     window.location.reload();
  //   });
  // };

  // const handleSubmit = () => {
  //   let data = 2022;

  //   dispatch({ type: FINANCIAL_YEAR, payload: data });
  //   // console.log(
  //   //   selectedFinancialYearId,
  //   //   financialYear,
  //   //   getFinancialYear[0].value
  //   // );
  //   // window.location.reload();
  //   setDropdownOpen(false);
  // };
  return (
    <>
      <hr className="gray-line" />
        <div className="locations">
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="media">
                            <div className="media-left">
                                <i className="pe-7s-alarm"></i>
                            </div>
                            <div className="media-body">
                                <h4>OPEN ALL WEEK</h4>
                                <h5>10:00 AM - 8:00 PM</h5>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="media">
                            <div className="media-left">
                                <i className="pe-7s-medal"></i>
                            </div>
                            <div className="media-body">
                                <h4>VIDEO CALL PURCHASING</h4>
                                <h5>Video call purchasing available</h5>
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="media">
                            <div className="media-left">
                                <i className="pe-7s-world"></i>
                            </div>
                            <div className="media-body">
                                <h4>FREE SHIPPING PAN INDIA</h4>
                                <h5>Free shipping pan India & Worldwide shipping chargeable</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
};

export default AppFooterTop;
