/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  productCategoryAction,
  customerAction,
  productAction
} from "../../store/actions";
import { keys } from "../../config/keys";
import Slideshow from "./Slideshow";
import BestSellerProductList from "../Products/BestSellerProductList";
import ReactOwlCarousel from "react-owl-carousel";
import Collection from "./Collections";

const Dashboard = () => {
  const dispatch = useDispatch<any>();
  const category_list = useSelector((obj: any) => obj.category_list);
  const _get_customer_list = () => dispatch(customerAction.get_customer_list());
  const _get_product_list = () => dispatch(productAction.get_product_list());
  const _get_category_list = () =>
    dispatch(productCategoryAction.get_category_list());

  useEffect(() => {
    _get_customer_list();
    _get_product_list();
    _get_category_list();

    // try {
    //   const script = document.createElement("script");
    //   script.src = "../../assets/scripts/functions.js";

    //   document.head.appendChild(script);
    //   return () => {
    //     document.head.removeChild(script);
    //   };
    // } catch (e) {}
  }, []);

  return (
    <Fragment>
      <Slideshow />
      {/* <div className="features">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="sale">
                <a href="services.html">
                  <div className="text-box">FREE SHIPPING WORLDWIDE</div>
                  <div className="icon-box">
                    <i className="pe-7s-cart"></i>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <div className="giveway">
                <a href="services.html">
                  <div className="text-box">GIVEAWAY EVERYWEEK</div>
                  <div className="icon-box">
                    <i className="pe-7s-gift"></i>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-md-4">
              <div className="freeship">
                <a href="services.html">
                  <div className="text-box">SALE UP TO 70% OFF ON TUESDAY</div>
                  <div className="icon-box">
                    <i className="pe-7s-diamond"></i>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="custom-boxes">
        <div className="container">
          <h2
            style={{
              color: "#25282c",
              fontSize: "20px",
              fontWeight: "normal",
              letterSpacing: "6px",
              margin: "0 0 10px",
              textAlign: "center"
            }}
          >
            FEATURED CATEGORIES
          </h2>
          {/* <div className="row">
            <div className="accordion1">
              <ul>
                <li tabIndex={1}>
                  <div>
                    <a href="#">
                      <h2>Lorem Ipsum</h2>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit
                      </p>
                    </a>
                  </div>
                </li>
                <li tabIndex={2}>
                  <div>
                    <a href="#">
                      <h2>Lorem Ipsum</h2>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit
                      </p>
                    </a>
                  </div>
                </li>
                <li tabIndex={3}>
                  <div>
                    <a href="#">
                      <h2>Lorem Ipsum</h2>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit
                      </p>
                    </a>
                  </div>
                </li>
                <li tabIndex={4}>
                  <div>
                    <a href="#">
                      <h2>Lorem Ipsum</h2>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit
                      </p>
                    </a>
                  </div>
                </li>
                <li tabIndex={5}>
                  <div>
                    <a href="#">
                      <h2>Lorem Ipsum</h2>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit
                      </p>
                    </a>
                  </div>
                </li>
                <li tabIndex={6}>
                  <div>
                    <a href="#">
                      <h2>Lorem Ipsum</h2>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit
                      </p>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">

          </div> */}
          <div className="row">
            {category_list.length > 0 &&
              category_list
                .filter((item: any, index: number) => index < 4)
                .map((obj: any, key: number) => {
                  if (
                    obj.isactive === 1 &&
                    obj.isdelete === 0 &&
                    obj.displayonweb === 1
                  )
                    return (
                      <div
                        className="col-md-3 col-sm-12 col-lg-3 "
                        style={{ padding: "10px" }}
                        key={Math.random()}
                      >
                        <div
                          className="fashion-store"
                          style={{
                            background: `url('${keys.ServicePath}/${obj.categoryimage}') no-repeat center center / cover`
                          }}
                        >
                          <a href={`/productlisting/${obj.id}`}>
                            {/* <img
                              src={`${keys.ServicePath}/${obj.categoryimage}`}
                              width={300}
                              alt={obj.categoryimage}
                            /> */}
                            <h3>{obj.category}</h3>
                            <div className="overlay"></div>
                          </a>
                        </div>
                      </div>
                    );
                })}
          </div>
        </div>
      </div>
      <div className="trending-clothing">
        <BestSellerProductList />
      </div>

      <div className="subscribe parallax">
        <div className="container">
          <i className="pe-7s-mail"></i>
          <h3>SUBSCRIBE</h3>
          <h5>Get the latest news & updates from Zorka</h5>
          <form action="subscribe.php" method="post" className="subscribe-form">
            <input
              type="email"
              name="your_email"
              placeholder="YOUR EMAIL ADDRESS"
            />
            <button type="submit">SUBSCRIBE</button>
          </form>
          <div className="row">
            <div className="col-sm-4 col-sm-offset-4">
              <div className="form-note"></div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="brand-logos">
        <div className="container">
          <ReactOwlCarousel
            className="owl-theme"
            margin={0}
            smartSpeed={1000}
            dots={true}
          >
            <div>
              <img
                className="img-responsive"
                src="assets/images/bershka.png"
                alt="img"
              />
            </div>
            <div>
              <img
                className="img-responsive"
                src="assets/images/pull-bear.png"
                alt="img"
              />
            </div>
            <div>
              <img
                className="img-responsive"
                src="assets/images/hm.png"
                alt="img"
              />
            </div>
            <div>
              <img
                className="img-responsive"
                src="assets/images/zara.png"
                alt="img"
              />
            </div>
            <div>
              <img
                className="img-responsive"
                src="assets/images/mango.png"
                alt="img"
              />
            </div>
            <div>
              <img
                className="img-responsive"
                src="assets/images/pull-bear.png"
                alt="img"
              />
            </div>
            <div>
              <img
                className="img-responsive"
                src="assets/images/hm.png"
                alt="img"
              />
            </div>
          </ReactOwlCarousel>
          <div id="carousel-3"></div>
        </div>
      </div> */}
    </Fragment>
  );
};

export default Dashboard;
