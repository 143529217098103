import { useDispatch, useSelector } from "react-redux";
import { cart } from "../../store/reducers/cart";
import { Link, useNavigate } from "react-router-dom";
import { keys } from "../../config/keys";
import { cartAction } from "../../store/actions";

const Cart = () => {
  const { cart } = useSelector((obj: any) => obj);
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const updateCart = (item: any) => {
    const { attribute_values, ...others } = item;
    let p = {
      ...others
    };
    dispatch(cartAction.addCartItem(p));
  };

  return (
    <div className="main">
      <div className="container">
        <div className="header-page">
          <h1>SHOPPING CART</h1>
        </div>

        <div className="main-content">
          {cart.item.length <= 0 && (
            <div className="row mgt-70">
              <div className="col-md-12">
                <h3>{`CART IS EMPTY`}</h3>
              </div>
              <div className="col-md-12 shop-shipping">
                <button
                  className="update-totals-btn"
                  onClick={() => navigate("/productlisting")}
                >{`Continue Shopping`}</button>
              </div>
            </div>
          )}
          {cart.item.length > 0 && (
            <form className="shop-form">
              <div className="table-responsive d-none d-sm-block">
                <table
                  className="shop-table table table table-sm"
                  cellSpacing="0"
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th>Product</th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cart?.item.map((x: any) => (
                      <tr className="cart_item">
                        <td className="product-remove">
                          <button
                            className="remove"
                            type="button"
                            onClick={() =>
                              dispatch(cartAction.deleteCartItem(x))
                            }
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: 0
                            }}
                          >
                            <i className="fa fa-times"></i>
                          </button>
                        </td>
                        <td className="product-thumbnail">
                          {x.product_images?.length > 0 ? (
                            <img
                              className="img-responsive"
                              src={`${keys.ServicePath}/${x.product_images[0].filepath}`}
                              alt="img"
                              width={50}
                            />
                          ) : (
                            <img
                              className="img-responsive"
                              src={require("../../assets/images/noImage.jpg")}
                              width={50}
                              alt="img"
                            />
                          )}
                          {/* <a href="single-product.html">
                          <img
                            className="img-responsive"
                            src="assets/images/small-product-1.jpg"
                            alt="img"
                          />
                        </a> */}
                        </td>
                        <td className="product-name">
                          <Link to={`/productdetails/${x.productid}`}>
                            {x.productname}
                          </Link>
                        </td>
                        <td className="product-price">
                          <span className="amount">
                            {keys.currencyFormat(x.online_sales_price_with_tax)}
                          </span>
                        </td>
                        <td className="product-quantity">
                          <div className="quantity">
                            <button
                              type="button"
                              className="minus-btn"
                              disabled={x.quantity <= 1}
                              onClick={() =>
                                updateCart({ ...x, quantity: x.quantity - 1 })
                              }
                            >
                              <i className="fa fa-minus"></i>
                            </button>
                            <input type="text" value={x.quantity} />
                            <button
                              type="button"
                              className="plus-btn"
                              onClick={() =>
                                updateCart({ ...x, quantity: x.quantity + 1 })
                              }
                            >
                              <i className="fa fa-plus"></i>
                            </button>
                          </div>
                        </td>
                        <td className="product-subtotal">
                          <span className="amount">
                            {keys.currencyFormat(x.itemtotal)}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="table-responsive d-block d-sm-none">
                <table
                  className="shop-table table table table-sm"
                  cellSpacing="0"
                >
                  <tbody>
                    {cart?.item.map((x: any) => (
                      <tr className="cart_item">
                        <td className="product-remove">
                          <button
                            className="remove"
                            type="button"
                            onClick={() =>
                              dispatch(cartAction.deleteCartItem(x))
                            }
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: 0
                            }}
                          >
                            <i className="fa fa-times"></i>
                          </button>
                        </td>
                        <td className="product-thumbnail">
                          {x.product_images?.length > 0 ? (
                            <img
                              className=""
                              src={`${keys.ServicePath}/${x.product_images[0].filepath}`}
                              alt="img"
                              width={50}
                            />
                          ) : (
                            <img
                              className=""
                              src={require("../../assets/images/noImage.jpg")}
                              width={50}
                              alt="img"
                            />
                          )}
                          {/* <a href="single-product.html">
                          <img
                            className="img-responsive"
                            src="assets/images/small-product-1.jpg"
                            alt="img"
                          />
                        </a> */}
                        </td>
                        <td className="">
                          <div className="row">
                            <div className="col-12">
                              <Link to={`/productdetails/${x.productid}`}>
                                {x.productname}
                              </Link>
                            </div>
                            {/* <div className="col-12">
                            <div className="product-price">
                              <span className="amount">
                                {keys.currencyFormat(
                                  x.online_sales_price_with_tax
                                )}
                              </span>
                            </div>
                          </div> */}
                            <div className="col-12">
                              <div className="product-subtotal">
                                <span className="amount">
                                  {keys.currencyFormat(x.itemtotal)}
                                </span>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="product-quantity">
                          <div className="quantity">
                            <button
                              type="button"
                              className="minus-btn"
                              disabled={x.quantity <= 1}
                              onClick={() =>
                                updateCart({ ...x, quantity: x.quantity - 1 })
                              }
                            >
                              <i className="fa fa-minus"></i>
                            </button>
                            <input type="text" value={x.quantity} />
                            <button
                              type="button"
                              className="plus-btn"
                              onClick={() =>
                                updateCart({ ...x, quantity: x.quantity + 1 })
                              }
                            >
                              <i className="fa fa-plus"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="row mgt-70">
                <div className="col-md-8"></div>
                <div className="col-md-4 col-sm-12">
                  <div className="shop-totals d-flex flex-column justify-content-end w-100">
                    <h3>CART TOTALS</h3>
                    <table className="totals-table ">
                      <tr>
                        <td className="text-right">Cart Subtotal:</td>
                        <td className="text-right">
                          <span className="amount">
                            {keys.currencyFormat(cart.itemPriceTotal)}
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="text-right">Shipping and Handling:</td>
                        <td className="text-right">Free Shipping</td>
                      </tr>
                      <tr>
                        <td className="text-right">Order Total:</td>
                        <td className="text-right">
                          <span className="amount">
                            {keys.currencyFormat(cart.itemPriceTotal)}
                          </span>
                        </td>
                      </tr>
                    </table>
                    <div className="shop-shipping">
                      <Link
                        to="/checkout"
                        className="update-totals-btn"
                      >{`Checkout`}</Link>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Cart;
