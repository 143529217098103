const USER_LIST = "USER_LIST";
const USER_TYPES = "USER_TYPES";
const CATEGORY_LIST = "CATEGORY_LIST";
const CATEGORY_BY_ID = "CATEGORY_BY_ID";
const PRODUCT_LIST = "PRODUCT_LIST";
const VENDOR_LIST = "VENDOR_LIST";
const PURCHASE_LIST = "PURCHASE_LIST";
const CUSTOMER_LIST = "CUSTOMER_LIST";
const CUSTOMER = "CUSTOMER";
const SALES_LIST = "PURCHASE_LIST";
const PRODUCT_DETAIL_BY_ID = "PRODUCT_DETAIL_BY_ID";
const GET_STATES = "GET_STATES";
const GET_CITIES = "GET_CITIES";
const GET_COUNTRIES = "GET_COUNTRIES";

export {
  USER_LIST,
  USER_TYPES,
  CATEGORY_LIST,
  PRODUCT_LIST,
  VENDOR_LIST,
  PURCHASE_LIST,
  CUSTOMER_LIST,
  SALES_LIST,
  PRODUCT_DETAIL_BY_ID,
  CATEGORY_BY_ID,
  GET_STATES,
  GET_CITIES,
  GET_COUNTRIES,
  CUSTOMER
};
