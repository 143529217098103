import { keys } from "../../config/keys";
import axios from "../../common/axios";
import { _dispatchPayload } from "./dispatchPayload";
import { SALES_LIST } from "../constTypes";

export const get_last_invoiceno = (callback) => async (dispatch) => {
  await axios
    .get(
      `${keys.ServicePath}/api/common/latest_invoice_number/sales_master_online`
    )
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res.data.rows);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};

export const save_sales = (data, callback) => async (dispatch) => {
  await axios
    .post(`${keys.ServicePath}/api/sales/online`, data)
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res.data.rows);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};

export const get_sales_list = (callback) => async (dispatch) => {
  await axios
    .get(`${keys.ServicePath}/api/sales`)
    .then((res) => {
      dispatch(_dispatchPayload(SALES_LIST, res.data.rows));
      if (callback?.onSuccess) callback.onSuccess(res.data.rows);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};

export const get_sales_by_id = (id, callback) => async (dispatch) => {
  await axios
    .get(`${keys.ServicePath}/api/sales/${id}`)
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res.data.rows);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};

export const get_online_sales_by_id = (id, callback) => async (dispatch) => {
  await axios
    .get(`${keys.ServicePath}/api/sales/online/${id}`)
    .then((res) => {
      if (callback?.onSuccess) callback.onSuccess(res.data.rows);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};

export const create_rp_order = (data, callback) => async (dispatch) => {
  await axios
    .post(`${keys.ServicePath}/api/payment/orders`, data)
    .then((res) => {
    
      if (callback?.onSuccess) callback.onSuccess(res.data);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};

export const verify_rp_order = (data, callback) => async (dispatch) => {
  await axios
    .post(`${keys.ServicePath}/api/payment/success`, data)
    .then((res) => {
      console.log("verify_rp_order",res.data);
      if (callback?.onSuccess) callback.onSuccess(res.data);
    })
    .catch((e) => {
      if (callback?.onError) callback?.onError(e);
    });
};
