/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import {
  AboutUs,
  Auth,
  Cart,
  Checkout,
  Dashboard,
  PrivacyPolicy,
  ProductDetail,
  ProductListing,
  Shipping
} from "../components";
import AppHeaderTop from "./AppHeaderTop";
import AppFooterTop from "./AppFooterTop";
import AppFooter from "./AppFooter";
import AppHeaderRoute from "./AppHeaderRoute";
import Invoice from "../components/Checkout/Invoice";
import Refund from "../components/Policies/Refund";
import Terms from "../components/Policies/Terms";
import Contact from "../components/Policies/Contact";

const AuthLayout = () => {
  // useEffect(() => {
  //   try {
  //     const script = document.createElement("script");
  //     script.src = "../../assets/scripts/functions.js";
  //     document.head.appendChild(script);
  //     // return () => {
  //     //   document.head.removeChild(script);
  //     // };
  //   } catch (e) {}
  // }, []);

  return (
    <div>
      {/* <AppHeaderTop /> */}
      <hr className="gray-line" />
      <AppHeaderRoute />
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/page/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/page/shipping-delivery-policy" element={<Shipping />} />
        <Route path="/page/refund-policy" element={<Refund />} />
        <Route path="/page/terms-and-conditions" element={<Terms />} />
        <Route path="/page/contact-us" element={<Contact />} />

        <Route path="/productlisting" element={<ProductListing />} />
        <Route
          path="/productlisting/:categoryId"
          element={<ProductListing />}
        />
        <Route path="/productdetail/:productId" element={<ProductDetail />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/invoice/:id" element={<Invoice />} />
        <Route path="/login" element={<Auth />} />
      </Routes>
      <footer className="bg_gray">
        <div>
          <AppFooterTop />
        </div>
        <div>
          <AppFooter />
        </div>
      </footer>
    </div>
  );
};

export default AuthLayout;
