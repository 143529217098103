import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import App from "./App";
import { store } from "./store";
import reportWebVitals from "./reportWebVitals";

import './index.css';
//import './App.css';
// import './assets/css/animate.css';
// import './assets/css/animate.css';
// import './assets/css/all.min.css';
// import './assets/css/ionicons.min.css'
// import './assets/css/themify-icons.css';
// import './assets/css/linearicons.css';
// import './assets/css/flaticon.css';
// import './assets/css/simple-line-icons.css';
// import './assets/owlcarousel/css/owl.carousel.min.css';
// import './assets/owlcarousel/css/owl.theme.css';
// import './assets/owlcarousel/css/owl.theme.default.min.css';
// import './assets/css/magnific-popup.css';
// import './assets/css/slick.css';
// import './assets/css/slick-theme.css';
// import './assets/css/style.css';
// import './assets/css/responsive.css';
// import "./assets/styles/styles.css";

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

const container = document.getElementById("root");
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <StrictMode>
    <ReduxProvider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ReduxProvider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
