import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get_geoLocations } from "../../store/actions/common";
import { Countries, States } from "../../store/reducers/common";
import { keys } from "../../config/keys";
import { get_customer_by_mobile } from "../../store/actions/customer";
import {
  create_rp_order,
  save_sales,
  verify_rp_order
} from "../../store/actions/sales";
import { useNavigate } from "react-router";
import { cartAction } from "../../store/actions";
export type SalesFormData = {
  shippingaddress: boolean;
  requestOTP: boolean;
  otp: string;
  otpVerified: boolean;
  mobile: string;
  address: string;
  address1: string;
  city: string;
  cityid: number;
  customername: string;
  id: number;
  pincode: string;
  stateid: number;
  statename: string;
  email: string;
  shippedby: string;
  shippingrefrenceno: string;
  producttotal: number;
  discounts: number;
  taxes: number;
  nettotal: number;
  paymentmethod: string;
  invoiceno: string;
  orderdate: string;
  productdetails?: [];
  countryid: number;
  countryname: string;
  shipping: number;
  orderId: string;
  paymentId: string;
};
const initialValue: SalesFormData = {
  shippingaddress: false,
  requestOTP: false,
  otp: "",
  otpVerified: false,
  mobile: "",
  address: "",
  address1: "",
  city: "",
  cityid: 0,
  customername: "",
  id: 0,
  countryid: 101,
  countryname: "",
  pincode: "",
  stateid: 0,
  statename: "",
  email: "",
  shippedby: "",
  shippingrefrenceno: "",
  producttotal: 0,
  discounts: 0,
  taxes: 0,
  nettotal: 0,
  paymentmethod: "online",
  invoiceno: "",
  orderdate: "",
  shipping: 0,
  orderId: "",
  paymentId: ""
};

const Checkout = () => {
  const { states, countries, cart, customer } = useSelector((obj: any) => ({
    states: obj?.geo_location_list?.states,
    countries: obj?.geo_location_list?.countries,
    cart: obj.cart,
    customer: obj.customer
  }));
  const navigate = useNavigate();

  const [formData, setFormData] = useState<SalesFormData>(initialValue);

  const [showMessage, setShowMessage] = useState({ show: false, message: "" });
  const dispatch = useDispatch<any>();

  const _get_locations = () => dispatch(get_geoLocations());

  const _get_customer = (mobile: string) =>
    dispatch(
      get_customer_by_mobile(mobile, {
        onSuccess: (res: any) => {
          if (res.rows.length > 0) {
            setFormData((prev: SalesFormData) => ({ ...prev, ...res.rows[0] }));
          }
        }
      })
    );

  useEffect(() => {
    _get_locations();
  }, []);

  const getDetails = () => {
    _get_customer(formData.mobile);
    // if (formData.otp === "12345") {
    //   formDataChange("otpVerified", true);
    // }
  };

  const placeOrder = () => {
    const { item, ...others } = cart;
    const statename =
      states?.find((x: States) => x.value === Number(formData.stateid))
        ?.label || "";
    const country =
      countries?.find((x: Countries) => x.value === Number(formData.countryid))
        ?.label || "";
    const body = {
      ...formData,
      salestransaction: item,
      ...others,
      statename,
      state: statename,
      country,
      paymentmethod: "online",
      nettotal: cart.itemPriceTotal + formData.shipping
    };

    console.log(body);
    // dispatch(
    //   create_rp_order(body, {
    //     onSuccess: (res: any) => {
    //       var options = {
    //         key: "rzp_test_MY1mSJGy2il9jP", // Enter the Key ID generated from the Dashboard
    //         amount: res?.amount_due, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    //         currency: "INR",
    //         name: "TWW", //your business name
    //         description: "Test Transaction",
    //         image: require("../../assets/images/twwLogo.png"),
    //         order_id: res?.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    //         //callback_url: `${keys.ServicePath}/api/payment/orders_callback`,
    //         handler: function (response: any) {
    //           alert(response.razorpay_payment_id);
    //           alert(response.razorpay_order_id);
    //           alert(response.razorpay_signature);
    //           const data = {
    //             orderCreationId: res?.id,
    //             razorpayPaymentId: response.razorpay_payment_id,
    //             razorpayOrderId: response.razorpay_order_id,
    //             razorpaySignature: response.razorpay_signature,
    //             orderId: response.razorpay_order_id,
    //             paymentId: response.razorpay_payment_id
    //           };

    //           dispatch(
    //             verify_rp_order(data, {
    //               onSuccess: (res: any) => {
    //                 dispatch(
    //                   save_sales(
    //                     { ...body, ...data },
    //                     {
    //                       onSuccess: (res: any) => {
    //                         const id = res[res.length - 1][0].id;
    //                         dispatch(cartAction.emptyCartItem([]));
    //                         navigate(`/invoice/${id}`);
    //                       }
    //                     }
    //                   )
    //                 );
    //               }
    //             })
    //           );
    //         },
    //         prefill: {
    //           //We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
    //           name: body.customername, //your customer's name
    //           email: body.email,
    //           contact: body.mobile //Provide the customer's phone number for better conversion rates
    //         },
    //         notes: {
    //           address: body.address
    //         },
    //         theme: {
    //           color: "#E6E6E6"
    //         }
    //       };
    //       console.log(options);
    //       var rzp1 = new (window as any).Razorpay(options);
    //       rzp1.on("payment.failed", function (response: any) {
    //         console.log(response);
    //         // alert(response.error.code);
    //         // alert(response.error.description);
    //         // alert(response.error.source);
    //         // alert(response.error.step);
    //         // alert(response.error.reason);
    //         // alert(response.error.metadata.order_id);
    //         // alert(response.error.metadata.payment_id);
    //       });
    //       rzp1.open();
    //     },
    //     onError: (err: any) => console.log(err)
    //   })
    // );
    const data = {
      orderCreationId: "",
      razorpayPaymentId: "",
      razorpayOrderId: "",
      razorpaySignature: "",
      orderId: "",
      paymentId: ""
    };
    dispatch(
      save_sales(
        { ...body, ...data },
        {
          onSuccess: (res: any) => {
            const id = res[res.length - 1][0].id;
            dispatch(cartAction.emptyCartItem([]));
            setShowMessage({
              show: true,
              message:
                "Our representetive will get in touch with you for placed order"
            });
            //navigate(`/invoice/${id}`);
          }
        }
      )
    );
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prev: SalesFormData) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };
  const formDataChange = (name: string, value: string | number | boolean) => {
    setFormData((prev: SalesFormData) => ({
      ...prev,
      [name]: value
    }));
  };

  const CheckValidation = () => {
    if (
      formData.mobile === "" ||
      formData.customername === "" ||
      formData.address === "" ||
      Number(formData.countryid) <= 0 ||
      Number(formData.stateid) <= 0 ||
      formData.pincode === ""
    )
      return true;

    return false;
  };

  return (
    <div className="main">
      <div className="container">
        <div className="header-page">
          <h1>CHECK OUT</h1>
        </div>
        <div className="main-content">
          <div className="check-out-content">
            {/* <h3>RETURNING CUSTOMERS?</h3>
            <div className="row">
              <div className="col-md-6 col-md-offset-3 returning-customer">
                <ul className="responsive-accordion responsive-accordion-default">
                  <li>
                    <div className="responsive-accordion-head">
                      Returning customer?{" "}
                      <span className="click-here">Click here to login</span>
                    </div>
                    <div className="responsive-accordion-panel">
                      <form action="#" className="login-form">
                        <h3>LOGIN</h3>
                        <label>USERNAME OR EMAIL ADDRESS*</label>
                        <input
                          className="input-form"
                          type="text"
                          id="your_id"
                        />
                        <label>YOUR PASSWORD*</label>
                        <input
                          className="input-form"
                          type="password"
                          id="your_password"
                        />
                        <button className="submit-btn" type="submit">
                          LOGIN
                        </button>
                        <div className="checkbox">
                          <label>
                            <input type="checkbox" /> REMEMBER ME?
                          </label>
                        </div>
                        <a href="#">LOST YOUR PASSWORD?</a>
                      </form>
                    </div>
                  </li>
                  <li>
                    <div className="responsive-accordion-head">
                      Have a coupon?{" "}
                      <span className="click-here">
                        Click here to enter your code
                      </span>
                    </div>
                    <div className="responsive-accordion-panel">
                      <form action="#">
                        <label>ENTER COUPON CODE</label>
                        <input
                          className="input-form"
                          type="text"
                          id="your_code"
                        />
                        <button className="apply-coupon-btn">
                          APPLY COUPON
                        </button>
                      </form>
                    </div>
                  </li>
                </ul>
              </div>
            </div> */}
            {showMessage.show && (
              <>
                <div className="alert alert-info">{showMessage.message}</div>
                <div className="col-md-12 shop-shipping">
                  <button
                    className="update-totals-btn"
                    onClick={() => navigate("/productlisting")}
                  >{`Continue Shopping`}</button>
                </div>
              </>
            )}
            {!showMessage.show && (
              <form className="check-out-form">
                <div className="billing-details">
                  <div className="row">
                    <div className="col-md-6">
                      <h3>BILLING DETAILS</h3>
                      <small>All fields are mandatory</small>
                      <input
                        className="input-form"
                        type="text"
                        name="mobile"
                        placeholder="MOBILE #"
                        onChange={handleInputChange}
                      />
                      <small>Please Enter your mobile number to proceed</small>
                      {!formData.requestOTP && (
                        <div className="payment-methods d-grid mt-0 mb-3">
                          <button
                            type="button"
                            className="place-order-btn"
                            onClick={(e) => {
                              e.preventDefault();
                              formDataChange("requestOTP", true);
                              formDataChange("otpVerified", true);
                              getDetails();
                            }}
                          >{`GET DETAILS`}</button>
                        </div>
                      )}
                      {/* {formData.requestOTP && !formData.otpVerified && (
                      <>
                        <input
                          className="input-form"
                          type="text"
                          onChange={(e) =>
                            setFormData((prev: SalesFormData) => ({
                              ...prev,
                              otp: e.target.value
                            }))
                          }
                          placeholder="ENTER OTP"
                        />
                        <div className="payment-methods d-grid mt-0 mb-3">
                          <button
                            type="button"
                            className="place-order-btn"
                            onClick={(e) => {
                              if (formData.otp === "12345") {
                                formDataChange("otpVerified", true);
                              }
                            }}
                          >{`VERIFY OTP`}</button>
                        </div>
                      </>
                    )} */}
                      {formData.otpVerified && (
                        <>
                          <input
                            className="input-form"
                            type="text"
                            placeholder="NAME"
                            name="customername"
                            value={formData.customername}
                            onChange={handleInputChange}
                          />
                          <input
                            className="input-form"
                            type="text"
                            placeholder="STREET ADDRESS"
                            name="address"
                            value={formData.address}
                            onChange={handleInputChange}
                          />
                          <input
                            className="input-form"
                            type="text"
                            placeholder="APARTMENT, SUITE, UNIT ECT. (OPTIONAL)"
                            name="address1"
                            value={formData.address1}
                            onChange={handleInputChange}
                          />

                          <div className="row">
                            <div className="col-md-6 pdr-5">
                              <select
                                className="custom-select fancy-select fancified input-form"
                                style={{ background: "#fff" }}
                                onChange={(e) => {
                                  const shipping = countries?.find(
                                    (x: Countries) =>
                                      Number(x.value) === Number(e.target.value)
                                  ).shippingcharge;
                                  formDataChange("countryid", e.target.value);
                                  formDataChange("shipping", shipping);
                                }}
                                value={formData.countryid}
                              >
                                <option value={-1}>{`COUNTRY`}</option>
                                {countries.map((x: Countries) => (
                                  <option value={x.value}>{x.label}</option>
                                ))}
                              </select>
                            </div>
                            <div className="col-md-6 pdr-5">
                              <select
                                className="custom-select fancy-select fancified input-form"
                                style={{ background: "#fff" }}
                                onChange={(e) =>
                                  formDataChange("stateid", e.target.value)
                                }
                                value={formData.stateid}
                              >
                                <option value={-1}>{`STATE`}</option>
                                {states.map((x: States) => (
                                  <option value={x.value}>{x.label}</option>
                                ))}
                              </select>
                            </div>
                            <div className="col-md-6 pdl-5">
                              <input
                                className="input-form"
                                type="text"
                                placeholder="PINCODE"
                                name="pincode"
                                value={formData.pincode}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12 pdr-5">
                              <input
                                className="input-form"
                                type="email"
                                placeholder="EMAIL"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          {formData.otpVerified && formData.id === 0 && (
                            <div className="checkbox">
                              <label>
                                <input type="checkbox" /> CREATE AN ACCOUNT?
                              </label>
                            </div>
                          )}
                          {/* <div className="checkbox">
                          <label>
                            <input
                              type="checkbox"
                              className="dif-add-chb"
                              onChange={(e) =>
                                setFormData((prev: any) => ({
                                  ...prev,
                                  shippingaddress: e.target.checked
                                }))
                              }
                            />
                            {"SHIP TO DIFFERENT ADDRESS"}
                          </label>
                        </div> */}
                        </>
                      )}

                      <textarea
                        className="textarea-form"
                        placeholder="NOTES ABOUT YOUR ORDER, E.G. SPECIAL NOTES FOR DELIVERY"
                      ></textarea>
                    </div>
                    {formData.shippingaddress && (
                      <div className="col-md-6">
                        <h3>{"SHIPPING ADDRESS"}</h3>
                        <div className="wrap-different-address">
                          <input
                            className="input-form"
                            type="text"
                            placeholder="STREET ADDRESS"
                          />
                          <input
                            className="input-form"
                            type="text"
                            placeholder="APARTMENT, SUITE, UNIT ECT. (OPTIONAL)"
                          />

                          <div className="row">
                            <div className="col-md-6 pdr-5">
                              <select
                                className="custom-select fancy-select fancified input-form"
                                style={{ background: "#fff" }}
                              >
                                <option value={-1}>{`STATE`}</option>
                                {states.map((x: States) => (
                                  <option value={x.value}>{x.label}</option>
                                ))}
                              </select>
                            </div>
                            <div className="col-md-6 pdl-5">
                              <input
                                className="input-form"
                                type="text"
                                placeholder="POSTCODE/ZIP"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="col-md-6">
                      <h3>CHECK OUT</h3>
                      <div className="table-responsive">
                        <table
                          className="check-out-table table"
                          cellSpacing="0"
                        >
                          <thead>
                            <tr>
                              <th></th>
                              <th className="product-name">Product</th>
                              <th className="no-border">Quantity</th>
                              <th className="product-subtotal">Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {cart?.item.map((x: any) => (
                              <tr className="cart_item" key={Math.random()}>
                                <td className="product-thumbnail">
                                  {x.product_images?.length > 0 ? (
                                    <img
                                      className="img-responsive"
                                      src={`${keys.ServicePath}/${x.product_images[0].filepath}`}
                                      alt="img"
                                      width={50}
                                    />
                                  ) : (
                                    <img
                                      className="img-responsive"
                                      src={require("../../assets/images/noImage.jpg")}
                                      width={50}
                                      alt="img"
                                    />
                                  )}
                                </td>
                                <td className="product-name text-secondary">
                                  {x.productname}
                                </td>

                                <td className="product-quantity">
                                  {x.quantity}
                                </td>
                                <td className="product-subtotal text-right">
                                  <span className="amount">
                                    {keys.currencyFormat(x.itemtotal)}
                                  </span>
                                </td>
                              </tr>
                            ))}
                            <tr>
                              <td></td>
                              <td className="product-name">Cart Subtotal</td>
                              <td className="no-border"></td>
                              <td className="product-subtotal text-right">
                                {keys.currencyFormat(cart.itemPriceTotal)}
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td className="product-name">
                                Shipping and Handling
                              </td>
                              <td className="no-border"></td>
                              <td className="product-subtotal text-right">
                                {formData.shipping > 0
                                  ? keys.currencyFormat(formData.shipping)
                                  : `Free Shipping`}
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td className="product-name">
                                <span className="highlight">Order Total</span>
                              </td>
                              <td className="no-border"></td>
                              <td className="product-subtotal text-right">
                                <span className="highlight">
                                  {keys.currencyFormat(
                                    cart.itemPriceTotal + formData.shipping
                                  )}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="payment-methods row">
                        <div className="d-grid col-md-6 col-lg-6 col-sm-12">
                          <button
                            className={
                              CheckValidation()
                                ? `place-order-btn-disabled`
                                : `place-order-btn`
                            }
                            type="button"
                            onClick={() => placeOrder()}
                            disabled={CheckValidation()}
                          >
                            PLACE ORDER
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
